/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { Suspense } from 'react';
import {
  ArticleOutlined,
  AssessmentOutlined,
  BadgeOutlined,
  Book,
  BookOutlined,
  CameraAltOutlined,
  Check,
  DescriptionOutlined,
  EmojiEventsOutlined,
  FileCopyOutlined,
  LeaderboardOutlined,
  MilitaryTechOutlined,
  Notifications,
  PeopleAltOutlined,
  Percent,
  PlayCircleOutline,
  Rule,
  RuleOutlined,
  SendOutlined,
  StarBorder,
  Store,
  StoreOutlined,
  ThumbUpOutlined,
  WorkspacePremiumOutlined
} from '@mui/icons-material';

import Dashboard from './views/excelencia/Dashboard/Dashboard.jsx';
import Template from './views/excelencia/Template';
import Submit from './views/excelencia/Submit';
import Loader from './components/Bridgestone/Loader.jsx';

const ActionPlan = React.lazy(() => import('./views/excelencia/ActionPlan/ActionPlan.jsx'));
const ContentMaterial = React.lazy(
  () => import('./views/excelencia/ContentMaterial/ContentMaterial.jsx')
);
const StoreProfile = React.lazy(() => import('./views/excelencia/StoreProfile/StoreProfile.jsx'));
const Ranking = React.lazy(() => import('./views/excelencia/Ranking/Ranking.jsx'));
const Videos = React.lazy(() => import('./views/excelencia/Videos/Videos.jsx'));
const Rules = React.lazy(() => import('./views/excelencia/Rules/Rules.jsx'));
const RequestRecover = React.lazy(() => import('./views/excelencia/Login/RequestRecover.jsx'));
const Recover = React.lazy(() => import('./views/excelencia/Login/Recover.jsx'));
const SacPersonal = React.lazy(() => import('./views/excelencia/Sac/SacPersonal'));
const SatisfactionSurvey = React.lazy(
  () => import('./views/excelencia/SatisfactionSurvey/SatisfactionSurvey')
);
const Social = React.lazy(() => import('./views/excelencia/Social/Social'));
const Login = React.lazy(() => import('./views/excelencia/Login/Login.jsx'));
const ExtraScores = React.lazy(() => import('./views/excelencia/ExtraScores/ExtraScores'));
const YourEmblems = React.lazy(() => import('./views/excelencia/YourEmblems/YourEmblems'));
const SelectForm = React.lazy(() => import('./views/excelencia/SelectForm'));
const SubmitManager = React.lazy(() => import('./views/excelencia/SubmitManager'));
const Users = React.lazy(() => import('./views/excelencia/User/Users'));
const UsersEdit = React.lazy(() => import('./views/excelencia/User/UsersEdit'));
const AdvisorValidations = React.lazy(
  () => import('./views/excelencia/AdvisorValidations/AdvisorValidations')
);
const ReportList = React.lazy(() => import('./views/excelencia/ReportList/ReportList'));
const StoresCrud = React.lazy(() => import('./views/excelencia/StoresCrud/StoresCrud'));
const AdvisorsCrud = React.lazy(() => import('./views/excelencia/AdvisorsCrud/AdvisorsCrud'));
const ExtraScoresCrud = React.lazy(
  () => import('./views/excelencia/ExtraScoresCrud/ExtraScoresCrud')
);
const VideosCrud = React.lazy(() => import('./views/excelencia/VideosCrud/VideosCrud'));
const SocialMediasCrud = React.lazy(
  () => import('./views/excelencia/SocialMediasCrud/SocialMediasCrud')
);
const ContentMaterialCrud = React.lazy(
  () => import('./views/excelencia/ContentMaterialCrud/ContentMaterialCrud')
);
const SpreadsheetUpload = React.lazy(
  () => import('./views/excelencia/SpreadsheetUpload/SpreadsheetUpload')
);
const Audit = React.lazy(() => import('./views/excelencia/Audit/Audit'));
const SubmitManagerAudit = React.lazy(() => import('./views/excelencia/SubmitManagerAudit'));
const NotificationsCrud = React.lazy(
  () => import('./views/excelencia/NotificationsCrud/NotificationsCrud')
);
const StoreSubscription = React.lazy(
  () => import('./views/excelencia/StoreProfile/StoreSubscription')
);
const RulesAndLicenses = React.lazy(
  () => import('./views/excelencia/RulesAndLicenses/RulesAndLicenses')
);
const TechnicalAssessment = React.lazy(
  () => import('./views/excelencia/TechnicalAssessment/SpreadsheetUpload')
);
const RulesCrud = React.lazy(() => import('./views/excelencia/RulesCrud/RulesCrud'));
const Results = React.lazy(() => import('./views/excelencia/Results/Results'));
const Gallery = React.lazy(() => import('./views/excelencia/Gallery/Gallery.jsx'));

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: AssessmentOutlined,
    component: Dashboard,
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 2, 3, 4, 5, 6, 7, 8],
    isAdmin: false
  },
  {
    path: '/results',
    name: 'Resultados',
    icon: Percent,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Results {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: false
  },
  {
    path: '/gallery',
    name: 'Galeria',
    icon: CameraAltOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Gallery {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 3],
    isAdmin: false
  },
  {
    path: '/forms',
    name: 'Questionários',
    icon: ArticleOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SelectForm {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/questions',
    name: 'Questionário',
    component: Template,
    layout: '/admin',
    store: false,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/submit',
    name: 'Submit',
    component: Submit,
    layout: '/admin',
    store: false,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/submits',
    name: 'Meus envios',
    icon: SendOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SubmitManager {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1, 2],
    isAdmin: false
  },
  {
    path: '/users',
    name: 'Usuários',
    icon: PeopleAltOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Users {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: false
  },
  {
    path: '/users-edit',
    name: 'Usuários Editar',
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <UsersEdit {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: false,
    profilesAllowed: [1],
    isAdmin: false
  },
  {
    path: '/validations',
    name: 'Validações',
    icon: RuleOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <AdvisorValidations {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [2, 7],
    isAdmin: false
  },
  {
    path: '/report-list',
    name: 'Relatórios',
    icon: FileCopyOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <ReportList {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/stores',
    name: 'Lojas',
    icon: StoreOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <StoresCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/advisors',
    name: 'CTCs',
    icon: BadgeOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <AdvisorsCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/extra-scores',
    name: 'Pontuações Extras',
    icon: WorkspacePremiumOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <ExtraScoresCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/upload-videos',
    name: 'Vídeos',
    icon: PlayCircleOutline,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <VideosCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/social-medias',
    name: 'Mídias Sociais',
    icon: ThumbUpOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SocialMediasCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/content-materials',
    name: 'Materiais de Apoio',
    icon: BookOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <ContentMaterialCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/rules-upload',
    name: 'Regulamento',
    icon: DescriptionOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <RulesCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/spreadsheet-upload',
    name: 'Pontos Tyrelife',
    icon: DescriptionOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SpreadsheetUpload {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/audit',
    name: 'Auditoria',
    icon: Check,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Audit {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/audit-form',
    name: 'Auditoria (questionários)',
    icon: Check,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SubmitManagerAudit {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/technical-assessment',
    name: 'Questionário Técnico',
    icon: ArticleOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <TechnicalAssessment {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [7],
    isAdmin: false
  },
  {
    path: '/plano-acao',
    name: 'Plano de ação',
    icon: DescriptionOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <ActionPlan {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/ranking',
    name: 'Ranking e pontuação',
    icon: EmojiEventsOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Ranking {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/perfil-loja',
    name: 'Perfil das lojas',
    icon: Store,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <StoreProfile {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/sac',
    name: 'Pesquisa de satisfação',
    icon: StarBorder,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SatisfactionSurvey {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  // {
  //   path: '/emblemas',
  //   name: 'Seus emblemas',
  //   icon: MilitaryTechOutlined,
  //   component: ({ ...props }) => (
  //     <Suspense fallback={Loader}>
  //       <YourEmblems {...props} />
  //     </Suspense>
  //   ),
  //   layout: '/admin',
  //   store: true,
  //   profilesAllowed: [6],
  //   isAdmin: false
  // },
  {
    path: '/pontuacoes-extras',
    name: 'Pontuações extras',
    icon: LeaderboardOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <ExtraScores {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false
  },
  {
    path: '/normas-licencas',
    name: 'Normas e licenças',
    icon: Rule,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <RulesAndLicenses {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [6],
    isAdmin: false,
    categoriesAllowed: ['BANDAG']
  },
  // {
  //   path: '/social',
  //   name: 'Redes sociais',
  //   icon: ThumbUpOutlined,
  //   component: ({ ...props }) => (
  //     <Suspense fallback={Loader}>
  //       <Social {...props} />
  //     </Suspense>
  //   ),
  //   layout: '/admin',
  //   content: true,
  //   profilesAllowed: [1, 6],
  //   isAdmin: false
  // },
  {
    path: '/material-apoio',
    name: 'Materiais de apoio',
    icon: Book,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <ContentMaterial {...props} />
      </Suspense>
    ),
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 2, 6, 7, 8],
    isAdmin: false
  },
  {
    path: '/videos',
    name: 'Vídeos',
    icon: PlayCircleOutline,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Videos {...props} />
      </Suspense>
    ),
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 2, 3, 4, 5, 6, 7, 8],
    isAdmin: false
  },
  {
    path: '/rules',
    name: 'Regulamento',
    icon: DescriptionOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Rules {...props} />
      </Suspense>
    ),
    layout: '/admin',
    content: true,
    profilesAllowed: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    isAdmin: false
  },
  {
    path: '/subscription',
    name: 'Inscrição',
    icon: DescriptionOutlined,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <StoreSubscription {...props} />
      </Suspense>
    ),
    layout: '/admin',
    content: false,
    profilesAllowed: [6],
    isAdmin: false,
    store: false
  },
  {
    path: '/notifications',
    name: 'Notificações',
    icon: Notifications,
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <NotificationsCrud {...props} />
      </Suspense>
    ),
    layout: '/admin',
    store: true,
    profilesAllowed: [1],
    isAdmin: true
  },
  {
    path: '/forgot-password',
    name: 'Recuperar Senha',
    icon: 'ni ni-key-25',
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <RequestRecover {...props} />
      </Suspense>
    ),
    layout: '/auth',
    hidden: true
  },
  {
    path: '/reset-password/:token',
    name: 'Redefinição de Senha',
    icon: 'ni ni-key-25',
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Recover {...props} />
      </Suspense>
    ),
    layout: '/auth',
    hidden: true
  },
  {
    path: '/sac-personal/:store/:category',
    name: 'Atendimento Pessoal realizado',
    icon: 'ni ni-key-25',
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <SacPersonal {...props} />
      </Suspense>
    ),
    layout: '/auth',
    hidden: true
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25',
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Login {...props} />
      </Suspense>
    ),
    layout: '/auth',
    hidden: true
  },
  {
    path: '/',
    name: 'Login',
    icon: 'ni ni-key-25',
    component: ({ ...props }) => (
      <Suspense fallback={Loader}>
        <Login {...props} />
      </Suspense>
    ),
    layout: '/auth',
    hidden: true
  }
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08",
  //   component: ({ ...props}) => <Suspense fallback={Loader}><Register {...props} /></Suspense>,
  //   layout: "/auth"
  // },
  // {
  //   path: "/closed",
  //   name: "Fim das Inscrições",
  //   icon: "ni ni-circle-08",
  //   component: ({ ...props}) => <Suspense fallback={Loader}><RegisterClosed {...props} /></Suspense>,
  //   layout: "/auth"
  // }
];
export default routes;
